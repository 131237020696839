// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import OtpSender from './components/otpsender';
//import CompanyList from './components/CompanyList';
import CompanyListAcord from './components/CompanyListAcord';
import CompanyDetails from './components/CompanyDetails';

const App = () => {
    return (
        <div>
            <h1>Welcome to the Company App</h1>
            
            <Router>
            <Routes>
                <Route path="/" element={<CompanyListAcord />} />
                <Route path="/companies/:id" element={<CompanyDetails />} />
            </Routes>
           </Router>
            
          
        </div>
        
    );
};

export default App;
