import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button , Accordion} from 'react-bootstrap';
import './CompanyList.css';

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [otpModalVisible, setOtpModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('http://localhost:5000/companies');
        setCompanies(response.data);
      } catch (err) {
        console.error('Error fetching companies:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  const handleShowModal = (company) => {
    setSelectedCompany(company);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setOtpModalVisible(false);
    setPhoneNumber('');
    setOtp('');
    setMessage('');
  };

  const handleSurveyButtonClick = (company) => {
    setSelectedCompany(company);
    setOtpModalVisible(true);
  };

  const sendOtp = async () => {
    try {
      const response = await axios.post('http://localhost:5000/send-otp', {
        phoneNumber,
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.error || 'Error sending OTP');
    }
  };

  const verifyOtpAndSubmitSurvey = async () => {
    try {
      const verifyResponse = await axios.post(
        'http://localhost:5000/verify-otp',
        { phoneNumber, otp }
      );

      if (verifyResponse.data.message) {
        await axios.post(
          `http://localhost:5000/survey/${selectedCompany.Id}`
        );
        alert('Survey submitted successfully!');
        handleCloseModal();
      }
    } catch (error) {
      setMessage(error.response?.data?.error || 'Error verifying OTP');
    }
  };

  const filteredCompanies = companies.filter((company) =>
    company.Name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="company-list-container">
      <h1 className="text-center mb-4">Company List</h1>

      <div className="search-bar mb-3">
        <input
          type="text"
          placeholder="Search Companies..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {isLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="company-list-container">
        {isLoading ? ( 
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <Accordion> 
            {filteredCompanies.map((company, index) => ( 
              <Accordion.Item key={company.Id} eventKey={index.toString()}> 
                <Accordion.Header>
                  <div style={{ display: 'flex', alignItems: 'center' }}> 
                    <img 
                      src={company.Logo} 
                      alt={company.Name} 
                      style={{ width: 30, height: 30, marginRight: 10 }} 
                    />
                    {company.Name} 
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <p><strong>Description:</strong> {company.Description}</p>
                  <p>Company ID: {company.Id}</p> 
                  <Button variant="secondary" onClick={() => handleShowModal(company)}>
                    Details 
                  </Button>
                  <Button
                    variant="info"
                    onClick={() => handleSurveyButtonClick(company)}
                    className="survey-button"
                  >
                    Survey
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          )}
        </div>
      )}

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCompany?.Name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img 
              src={selectedCompany?.Logo} 
              alt={selectedCompany?.Name} 
              className="company-logo-modal" 
              style={{ width: 50, height: 50, marginRight: 10 }} 
            />
            <p>
              <strong>Description:</strong> {selectedCompany?.Description}
            </p>
          </div>
          <p>Company ID: {selectedCompany?.Id}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            onClick={() => handleSurveyButtonClick(selectedCompany)}
          >
            Survey
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={otpModalVisible}
        onHide={handleCloseModal}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please enter your phone number to receive an OTP. You will be
            redirected to the survey page after successful verification.
          </p>
          <input
            type="text"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <Button variant="info" onClick={sendOtp}>
            Send OTP
          </Button>
          <p>
            Enter the OTP you received:
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </p>
          <Button variant="info" onClick={verifyOtpAndSubmitSurvey}>
            Verify OTP and Submit Survey
          </Button>
          <p style={{ color: 'red' }}>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompanyList;